<template>
  <div class="file-input">
    <label
      class="file-input__label"
      :class="{
        'file-input--error': errors.length,
        'file-input--value': value && value.length,
      }"
    >
      <span class="file-input__title">
        {{ title }}
        <span>{{ required ? "*" : "" }}</span>
      </span>
      <span class="file-input__container">
        <input
          ref="input"
          type="file"
          :autofocus="autofocus"
          :disabled="disabled"
          :multiple="multiple"
          @change="onChangeHandle"
        />
        <span v-for="(item, key) in value" :key="key" class="file-input__button">
          <span class="file-input__name">
            {{ item.name }}
          </span>

          <span v-if="multiple" class="file-input__delete" @click="deleteHandle(key, $event)"></span>
        </span>
      </span>
    </label>
    <div v-if="errors && errors.length" class="file-input__errors">
      <pre v-for="(e, i) in errors" :key="i">{{ e }}</pre>
    </div>
    <div v-if="messages && messages.length" class="file-input__messages">
      <pre v-for="(m, i) in messages" :key="i">{{ m }}</pre>
    </div>
  </div>
</template>

<script>
export default {
  name: "FileInputComponent",
  props: {
    title: {
      type: String,
      required: true,
    },
    errors: {
      type: Array,
      default() {
        return [];
      },
    },
    messages: {
      type: Array,
      default() {
        return [];
      },
    },
    value: Array,
    disabled: Boolean,
    autofocus: Boolean,
    required: Boolean,
    multiple: String,
  },
  data() {
    return {
      fileList: [],
      file: null,
    };
  },
  methods: {
    fileNamesList() {
      if (this.value && this.value.length) {
        return Array.from(this.value)
          .map((l) => l.name)
          .join(", ");
      }
      return "Загрузить файл";
    },
    onChangeHandle($event) {
      $event.preventDefault();

      if (this.$props.multiple){
        this.fileList.push(...$event.target.files);
        this.$emit("input", this.fileList);
      } else {
        this.file = $event.target.files;
        this.$emit("input", this.file);
      }
    },
    deleteHandle(key, $event) {
      $event.preventDefault();

      this.fileList.splice(key, 1);
    },
  },
};
</script>

<style lang="stylus">
.file-input {
  display grid
  grid-gap 5px

  &__label {
    display grid
    grid-gap 5px
    position relative
  }

  &__button {
    position relative;
    background: var(--white);
    border: 1px solid var(--gray-dark);
    border-radius: 5px;
    padding 3px 10px
    font-weight: 500;
    font-size: 1em;
    line-height: 26px;
    color: var(--dark-o3);
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;

    ^[0]--value {
      color var(--dark)
      border-color var(--dark)
    }
  }

  &__container {
    display flex
    align-items center
    background: var(--white);
    border: 1px solid var(--gray-dark);
    border-radius: 5px;
    height 50px
    padding 0 15px
    cursor pointer
    gap 5px
    flex-direction row
    flex-wrap wrap
    height 100%
    min-height 50px
    padding 10px

    input {
      absolute left top
      width 0
      height 0
      overflow hidden
      appearance none
      outline none
    }

    ^[0]--focus & {
      border: 1px solid var(--main);
      box-shadow: 0 0 0 3px var(--main-o15);
    }

    ^[0]--error & {
      border: 1px solid var(--red);
      box-shadow: 0 0 0 3px var(--red-o15);
    }
  }

  &__title {
    font-weight: 500;
    font-size: 0.75em;
    line-height: 14px;
    color: var(--dark-light);

    span {
      color: var(--red);
    }
  }

  &__action {
    padding 15px
    padding-right 10px
    background none
    border-radius 5px
    display inline-flex
    align-items center
    justify-content center
    border 1px solid transparent

    &:focus {
      border-color var(--main)
      box-shadow: 0 0 0 3px var(--main-o15);
    }

    > .icon {
      width 20px
      height: 20px;

      svg path {
        fill var(--dark)
      }
    }
  }

  &__delete{
    position absolute;
    top -5px;
    right -5px;
    z-index 9;
    background-color var(--dark)
    border-radius 50%
    padding 10px
    width 10px
    height 10px

    &:before{
      content 'x'
      color #fff;
      position relative
      top: -15px;
      right: 3px;
      font-size: 12px;
    }
  }

  &__messages,
  &__errors {
    font-weight: 500;
    font-size: 0.75em;
    line-height: 14px;
    display grid
    grid-gap 5px
  }

  &__messages {
    color var(--dark-light)
  }

  &__errors {
    color var(--red)
  }
}
</style>
